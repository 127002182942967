<template>
	<div class="challenge">
		<p class="line-height-3">{{ description }}</p>

		<Button
			class="challenge-button"
			label="Оспорить"
			outlined
			@click="redirectToChallengePage()" />
	</div>
</template>

<script setup lang="ts">
	import { LayoutTypes } from "@/assets/ts/enums/detail-with-payment/LayoutTypes";

	const props = defineProps<{
		challengeLink: string;
		pageType: LayoutTypes;
	}>();

	const router = useRouter();

	const redirectToChallengePage = () => router.push(`${router.currentRoute.value.path}/${props.challengeLink}`);

	const description = computed((): string => {
		const text: Record<LayoutTypes, string> = {
			[LayoutTypes.Penalty]: "Если Вы не согласны с данным штрафом (на фото указан другой автомобиль/гнз указан некорректно/др.), пожалуйста, нажмите кнопку «Оспорить».",
			[LayoutTypes.TollRoads]: "Если Вы не согласны с данным проездом (на фото указан другой автомобиль/гнз указан некорректно/др.), пожалуйста,  нажмите кнопку «Оспорить». ",
		};

		return text[props.pageType];
	});
</script>

<style scoped lang="scss">
	@import "challenge";
</style>
